export function Dialog({ onClose, children }) {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div className="dialog__container">
            <div className="dialog">
                <div className="close__icon" onClick={handleClose}>X</div>
                <div className="content">{children}</div>
            </div>
        </div>
    );
}