import { InputErrorWrapper } from '../../shared';
import authenticationService from "../../../services/authentication.service";
import { validateEmail, backendToUiErrors, hasErrors } from "../../../validators";
import { useState } from 'react';
import { CodeVerification } from '../code-verification';
import { NewPassword } from '../new-password';
import { AuthenticationStepsSuccess } from "../authentication-steps-success";

export function ForgotPassword({ onLoginClicked, onSetIsBusy }) {
    const [stage, setStage] = useState("email-verification");
    const [data, setData] = useState({ emailId: '', code: '', password: '' });

    const handleEmailVerificationSuccess = () => {
        setStage("code-verification");
    }

    const handleCodeVerificationSuccess = () => {
        setStage("reset-password");
    }

    const handlePasswordResetSuccess = () => {
        setStage("success");
    }

    const handleCodeChange = (code) => {
        setData({ ...data, code });
    }

    const handleEmailIdChange = (emailId) => {
        setData({ ...data, emailId });
    }

    const handlePasswordChange = (password) => {
        setData({ ...data, password });
    }

    const resetPassword = () => {
        return authenticationService.resetPassword(data);
    }

    const onCodeVerificationError = (uiErrors) => {
        // problem with code
        if (uiErrors.code) {
            return uiErrors;
        }
        // password related error only - move to next stage
        else if (uiErrors.password) {
            setStage("reset-password");
        }
    }

    return (
        <>
            <div className="forgot-password__container">
                {stage == "email-verification" &&
                    <EmailVerfication
                        data={data}
                        onEmailIdChange={handleEmailIdChange}
                        onLoginClicked={onLoginClicked}
                        onSetIsBusy={onSetIsBusy}
                        onSuccess={handleEmailVerificationSuccess} />
                }
                {stage == "code-verification" &&
                    <CodeVerification
                        data={data}
                        onSetIsBusy={onSetIsBusy}
                        onSuccess={handleCodeVerificationSuccess}
                        onError={onCodeVerificationError}
                        onCodeChange={handleCodeChange}
                        verificationMethod={resetPassword} />
                }
                {stage == "reset-password" &&
                    <NewPassword
                        data={data}
                        onPasswordChange={handlePasswordChange}
                        onSetIsBusy={onSetIsBusy}
                        onSuccess={handlePasswordResetSuccess}
                        verificationMethod={resetPassword} />
                }
                {stage == "success" &&
                    <AuthenticationStepsSuccess message={"Your password has been changed successfully"} />
                }
            </div>
        </>
    );
}

function EmailVerfication({ data: { emailId }, onEmailIdChange, onLoginClicked, onSetIsBusy, onSuccess }) {
    const [validations, setValidations] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onEmailIdChange(value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        var validation = validateData({ emailId });
        setValidations(validation);

        if (hasErrors(validation)) {
            return;
        }

        onSetIsBusy(true);
        try {
            var result = await authenticationService.getResetPasswordCode(emailId);
            onSuccess();
        }
        catch (backendError) {
            const { response: errors } = backendError;

            if (!errors) {
                return;
            }

            const uiErrors = backendToUiErrors(errors, null, errorTypeMapper);
            setValidations(uiErrors);
        }

        onSetIsBusy(false);
    }

    return (
        <form className="form-field-spacing">
            <div className="form-field-wrap required">
                <label htmlFor="form-field-name" className="form-field-label">Email ID</label>
                <div className="form-field-input">
                    <InputErrorWrapper errors={validations.emailId}>
                        <input type="text" name="emailId" className="form-field" required="" aria-required="true" value={emailId} onChange={handleInputChange} />
                    </InputErrorWrapper>
                </div>
            </div>

            <div className="container__row">
                <a href="#" onClick={onLoginClicked}>
                    Signin instead
                </a>
                <button type="submit" className="button small next" onClick={handleSubmit}>
                    Next
                </button>
            </div>
        </form>
    );
}

function validateData({ emailId }) {
    var validation = {};

    if (!emailId) {
        validation.emailId = ["Email Id is required"]
    }

    else if (!validateEmail(emailId)) {
        validation.emailId = ["Email id is not in correct format"]
    }

    return validation;
}

function errorTypeMapper(errorType, field) {
    if (errorType == "UserIdNotAvailable") {
        return "EmailId is not available";
    }
}

