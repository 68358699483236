import './App.css';
import { AppPage } from './components/app-page';
import store from './redux/store/store'
import { Provider } from 'react-redux'

function App() {
  const isLocal = process.env.NODE_ENV == "development";

  if (!isLocal) {
    var isHttps = true;
    
    if (window.location.origin.includes("http:")) {
      isHttps = false;
      var href = window.location.href;
      var newHref = href.replace("http:", "https:");
      window.location.replace(newHref);
    }

    if (!isHttps) {
      return null;
    }
  }

  return (
    <Provider store={store}>
      <div className="app">
        <AppPage />
      </div>
    </Provider>
  );
}

export default App;
