import { useState, useRef } from "react";
import { InputErrorWrapper } from '../../shared';
import { validateEmail, backendToUiErrors, hasErrors } from "../../../validators";
import authenticationService from "../../../services/authentication.service";
import { CodeVerification } from '../code-verification';
import { AuthenticationStepsSuccess } from "../authentication-steps-success";

export function SignUp({ onLoginClicked, onAuthenticationSuccess, onSetIsBusy }) {
    const [stage, setStage] = useState("signup");
    const [signUpCode, setSignUpCode] = useState('');

    var signupDataRef = useRef();

    const onSignupStageCompleted = () => {
        setStage("email-confirmation");
    }

    const handleSignup = (signupData) => {
        signupDataRef.current = signupData;
    }

    const handleEmailConfirmationCompleted = (user) => {
        setStage("success");
        onAuthenticationSuccess(user);
    }

    const signUp = () => {
        return authenticationService.login({
            emailConfirmationCode: signUpCode,
            emailId: signupDataRef.current.emailId,
            password: signupDataRef.current.password
        });
    }

    const handleCodeChange = (code) => {
        setSignUpCode(code);
    }

    return (
        <div className="signup__container">
            {stage == "signup" &&
                <SignupForm onLoginClicked={onLoginClicked}
                    onSignupStageCompleted={onSignupStageCompleted}
                    onSignUp={handleSignup}
                    onSetIsBusy={onSetIsBusy}
                />}
            {stage == "email-confirmation"
                && <CodeVerification
                    data={{ code: signUpCode, emailId: signupDataRef.current.emailId }}
                    onSuccess={handleEmailConfirmationCompleted}
                    onSetIsBusy={onSetIsBusy}
                    onCodeChange={handleCodeChange}
                    verificationMethod={signUp}
                />

            }
            {stage == "success"
                && <AuthenticationStepsSuccess message={'Successfully signed up!!'} />}
        </div>
    );
}

function SignupForm({ onLoginClicked, onSignupStageCompleted, onSignUp, onSetIsBusy }) {
    const [signupData, setSignupData] = useState({ fullName: '', emailId: '', password: '', confirmPassword: '', showPassword: false });
    const { fullName, emailId, password, confirmPassword, showPassword } = signupData;
    const [validations, setValidations] = useState({});
    const [serverError, setServerError] = useState();

    const handleInputChange = (event) => {
        let { name, value, checked } = event.target;

        if (event.target.type == "checkbox") {
            value = checked;
        }

        setSignupData({ ...signupData, [name]: value });
    }

    const handleSignup = async (event) => {
        event.preventDefault();
        setServerError(null);
        var validation = validateSignupData(signupData);
        setValidations(validation);

        if (hasErrors(validation)) {
            return;
        }

        onSetIsBusy(true);
        try {
            onSignUp(signupData);
            var result = await authenticationService.signup(signupData);

            if (result.status == "PendingActive") {
                onSignupStageCompleted();
            }
        }
        catch (backendError) {
            const { response: errors } = backendError;

            if (!errors) {
                setServerError("Unknown error occured");
                onSetIsBusy(false);
                return;
            }

            const uiErrors = backendToUiErrors(errors);
            setValidations(uiErrors);
        }

        onSetIsBusy(false);
    }

    const passwordInputType = showPassword ? "text" : "password";

    return (
        <section className="signup-form__container">
            <form className="form-field-spacing">
                {serverError && <div className="">{serverError}</div>}
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">Full Name (required)</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.fullName}>
                            <input type="text" name="fullName" className="form-field" required="" aria-required="true" value={fullName} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-email" className="form-field-label">Email (required)</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.emailId}>
                            <input type="email" id="form-field-email" name="emailId" className="form-field" required="" aria-required="true" value={emailId} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>
                <div className="form-field-wrap required">
                    <InputErrorWrapper errors={validations.password}>
                        <div className="passwords__container">
                            <div className="password__container">
                                <label htmlFor="form-field-email" className="form-field-label">Password (required)</label>
                                <div className="form-field-input">
                                    <input type={passwordInputType} id="form-field-email" name="password" className="form-field" required="" aria-required="true" value={password} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="password__container confirm-password">
                                <label htmlFor="form-field-email" className="form-field-label">Confirm Password (required)</label>
                                <div className="form-field-input">
                                    <input type={passwordInputType} id="form-field-email" name="confirmPassword" className="form-field" required="" aria-required="true" value={confirmPassword} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                    </InputErrorWrapper>
                </div>
                <div className="container__row">
                    <input type="checkbox" name="showPassword" checked={showPassword} onChange={handleInputChange} />
                    <span className="checkbox__label">Show Password</span>
                </div>
                <div className="action">
                    <a href="#" onClick={onLoginClicked}>
                        Signin instead
                    </a>
                    <button type="submit" className="button small button-form next" onClick={handleSignup}>
                        Next
                    </button>
                </div>
            </form>
        </section>
    );
}

function validateSignupData(signupData) {
    var validation = {};

    if (!signupData.fullName) {
        validation.fullName = ["Full Name is required"]
    }

    if (!validateEmail(signupData.emailId)) {
        validation.emailId = ["Email id is not in correct format"]
    }

    if (!signupData.password) {
        validation.password = ["Password is required"]
    }

    if (signupData.password && signupData.password != signupData.confirmPassword) {
        validation.password = ["Password and Confirm Password don't match"]
    }

    return validation;
}