import companyLogo from './5.jpg';
import { WhyUs } from '../why-us';
import { Events } from '../events';
import { useParams, useNavigate } from "react-router-dom";

export function Home() {
    const params = useParams();
    const navigate = useNavigate();
    let backgroundUrl = "./home-bg.jpg";
    const path = require('./home-bg.jpg');
    backgroundUrl = path;

    const handleExploreCourses = () => {
        navigate("/courses")
    }

    return (
        <div className="home-container">
            <div className="home__banner" style={{ backgroundImage: `url(${companyLogo})` }}>
                <div>
                    <div className="home__banner-text">NS Edutech</div>
                    <div className="home__banner-text small">The best place to learn ReactJS, Angular, and .Net.</div>

                    <button className="button block explore-courses" onClick={handleExploreCourses}>
                        Explore our courses
                    </button>
                </div>
            </div>
            {/* <div className='events'><Events /></div> */}
            <div>
                <WhyUs />
            </div>
        </div>
    );
}

  // {/* <div style={{ backgroundImage: 'url(' + require('./home-bg.jpg') + ')' }}> */}
  //  {/* <Login/> */}