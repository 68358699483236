import httpService from './http.service';

// Shall interceptors also have order field to allow us to sort? So not all caller has to add all interceptors
export default function getHttpInterceptedService(requestInterceptors = [], responseInterceptors = []) {
    return {
        get: (url, requestParameters) => interceptedGet(url, requestParameters, requestInterceptors, responseInterceptors),
        post: (url, data, requestParameters) => interceptedPost(url, data, requestParameters, requestInterceptors, responseInterceptors),
        delete: (url, data, requestParameters) => interceptedDelete(url, data, requestParameters, requestInterceptors, responseInterceptors),
    }
}

// we expect four params - url, data, requestParameters, extraParameters
function interceptRequest(url, data, requestParameters, extraParameters, requestInterceptors) {
    let requestObjects = { url, data, requestParameters, extraParameters };

    requestInterceptors.forEach(interceptor => {
        requestObjects = interceptor(requestObjects);
    });

    return { url, data, requestParameters } = requestObjects; // Note this
}

function interceptedResponse(responsePromise, responseInterceptors) {
    responseInterceptors.forEach(i => {
        responsePromise = responsePromise.then(response => i(response));
    });

    return responsePromise;
}

function interceptedGet(url, requestParameters, requestInterceptors, respsonseInterceptors) {
    const extraParameters = getExtraParameters("GET");

    const { url: newUrl, requestParameters: newRequestParameters } =
        interceptRequest(url, null, requestParameters, extraParameters, requestInterceptors);

    let responsePromise = httpService.get(newUrl, newRequestParameters);
    return interceptedResponse(responsePromise, respsonseInterceptors)
}

function interceptedPost(url, data, requestParameters, requestInterceptors, respsonseInterceptors) {
    const extraParameters = getExtraParameters("POST");

    const { url: newUrl, data: newData, requestParameters: newRequestParameters } =
        interceptRequest(url, data, requestParameters, extraParameters, requestInterceptors);

    let responsePromise = httpService.post(newUrl, newData, newRequestParameters);
    return interceptedResponse(responsePromise, respsonseInterceptors)
}

function interceptedDelete(url, data, requestParameters, requestInterceptors, respsonseInterceptors) {
    const extraParameters = getExtraParameters("DELETE");

    const { url: newUrl, data: newData, requestParameters: newRequestParameters } =
        interceptRequest(url, data, requestParameters, extraParameters, requestInterceptors);

    let responsePromise = httpService.delete(newUrl, newData, newRequestParameters);
    return interceptedResponse(responsePromise, respsonseInterceptors)
}

function getExtraParameters(method) {
    return { method };
}