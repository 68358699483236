import { useDispatch, useSelector } from 'react-redux';
import { showLogin as showLoginAction } from '../../redux/reducers/ui';
import { AuthenticationDialog } from '../authentication/AuthnticationDialog';
import { CourseRegistration } from '../course-registration';
import { Dialog } from '../shared';
import { useState } from 'react';
import courseRegistrationService from '../../services/course-registration.service';
import { backendToUiErrors } from "../../validators";

export function CourseSchedule() {
    const dispatch = useDispatch();
    const [isRegistrationInProgress, setIsRegistrationInProgress] = useState(false);
    const [uiStage, setUIStage] = useState({ showMain: true });
    const showLogin = useSelector((state) => state.ui.showLogin);
    const isUserLoggedIn = useSelector((state) => state.authentication.isUserLoggedIn);
    let showRegistrationConfirmation = isUserLoggedIn && !showLogin && isRegistrationInProgress;

    const handleRegister = () => {
        if (!isUserLoggedIn) {
            dispatch(showLoginAction(true));
        }

        setIsRegistrationInProgress(true);

        //setShowRegistrationConfirmation(true);
    };

    const handleRegistrationClose = () => {
        setIsRegistrationInProgress(false);
        setUIStage({ showMain: true });
    }

    const getUI = () => {
        if (uiStage.showMain) {
            return <CourseRegistration onCancel={handleRegistrationCancel} onSubmit={handleRegistrationConfirmation} />;
        }

        if (uiStage.showAlreadyRegistered) {
            return getAlreadyRegisteredUI();
        }
    }

    const handleRegistrationCancel = () => {
        setIsRegistrationInProgress(false);
    }

    const getAlreadyRegisteredUI = () => {
        return (
            <>
                <div className='duplicate-registration'>You are already registered for the course</div>
            </>
        );
    }

    const handleRegistrationConfirmation = async () => {
        try {
            var result = await courseRegistrationService.register("react-mar2022");
            setIsRegistrationInProgress(false);
        }
        catch (backendError) {
            const { status, response: errors } = backendError;

            if (!errors) {
                return;
            }

            if (status === 400) {
                const uiErrors = backendToUiErrors(errors, null, errorTypeMapper);

                if (uiErrors.registration) {
                    setUIStage({ showAlreadyRegistered: true });
                }
            }
        }
    }

    return (
        <>
            <div className="course-schedule__container">
                <h2 className="title">
                    {/* <span className="outline-title-icon-btm">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256l-44.277-48.335 18.985-62.787-60.937-24.258-10.133-64.772-65.419 4.482-37.261-53.986-56.958 32.52-56.958-32.519-37.261 53.985-65.418-4.482-10.134 64.772-60.936 24.257 18.984 62.787-44.277 48.336 44.277 48.335-18.984 62.787 60.936 24.257 10.134 64.772 65.418-4.482 37.261 53.986 56.958-32.519 56.958 32.52 37.26-53.986 65.419 4.482 10.133-64.772 60.936-24.257-18.983-62.787 44.277-48.336zm-115.594 111.943l-8.387 53.606-54.105-3.707-30.795 44.619-47.119-26.902-47.119 26.902-30.795-44.619-54.106 3.707-8.387-53.606-50.391-20.059 15.69-51.89-36.637-39.994 36.636-39.994-15.689-51.89 50.391-20.059 8.387-53.606 54.105 3.707 30.796-44.619 47.119 26.903 47.12-26.902 30.795 44.619 54.105-3.707 8.387 53.606 50.39 20.059-15.69 51.89 36.638 39.993-36.637 39.994 15.69 51.89-50.392 20.059zM203.311 161.086c-24.367 0-44.191 19.824-44.191 44.191s19.824 44.191 44.191 44.191 44.191-19.824 44.191-44.191-19.823-44.191-44.191-44.191zm0 55.749c-6.372 0-11.558-5.185-11.558-11.558 0-6.372 5.185-11.558 11.558-11.558s11.558 5.185 11.558 11.558c0 6.372-5.186 11.558-11.558 11.558zM308.69 262.78c-24.366 0-44.191 19.824-44.191 44.191s19.825 44.191 44.191 44.191c24.366 0 44.191-19.824 44.191-44.191s-19.825-44.191-44.191-44.191zm0 55.749c-6.372 0-11.558-5.185-11.558-11.558 0-6.372 5.185-11.558 11.558-11.558 6.372 0 11.558 5.185 11.558 11.558-.001 6.372-5.186 11.558-11.558 11.558zM178.027 326.606l130.529-161.701 25.392 20.497-130.529 161.701z" fill="#006ebe" /></svg>
        </span> */}
                    Courses Schedule        </h2>
                <ul className="promoted-courses-list">
                    <li className="promoted-course">
                        <div className="course-details">
                            <div className="course-dates">
                                <span>1st Mar 2022</span>
                                <svg className="arrow-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path></svg>
                                31st Mar 2022
                            </div>
                            <div className="course-location">Online</div>
                        </div>
                        <div className="course-booking">
                            <a className="a-button" href="/booking?ceid=bpm-10100025-20211227&cc=bpm&date=2021-12-27&vid=10100025&type=remote">
                                <div className="course-type">Fees</div>
                                <div className="original-price">7,999 INR</div>
                                <div className="price">6,999 INR</div>
                            </a>
                        </div>
                        <div className="course-booking">
                            <span className="a-button" href="/booking?ceid=bpm-10100025-20211227&cc=bpm&date=2021-12-27&vid=10100025&type=classroom">
                                <div className="course-type">Timing</div>
                                <div className="price">7:30am to 9am</div>
                                <div className="price">Mon to Fri</div>
                            </span>
                        </div>
                        <div className="course-booking">
                            <div className="course-type">Registration</div>
                            <div className="price">Open</div>
                        </div>
                        <div className="select-course">
                            <button className="button block small" onClick={handleRegister}>
                                Register
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            {showRegistrationConfirmation
                &&
                <Dialog onClose={handleRegistrationClose}>
                    {/* {isBusy && <BusyOverlay spinnerClassName='spinner' />} */}
                    <div className="course-registration-confirmation__container">
                        {getUI()}
                    </div>
                </Dialog>
            }
            {/* <LoginDialog/> */}
        </>
    )
}

function errorTypeMapper(errorType, field) {
    if (errorType == "Duplicate") {
        return "You are already registered for the course.";
    }

    return "";
}