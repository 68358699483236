import { jsonHttpService } from './http';

function sendMessage(message) {
    const uri = process.env.REACT_APP_API_URI + "enquiry/save";
    return jsonHttpService.post(uri, message);
}

const enquiryService = {
    sendMessage
}

export default enquiryService;