import { Header } from "./header";

export function User() {
    return (
        <div className="user__container">
            <div className="container__limited-width header">
                <Header />
            </div>
            <div>We know you are eager to explore your dashboard. We are working hard to make it live soon.</div>
        </div>
    );
}