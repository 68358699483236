import { Authentication } from "./Authentication";
import { Dialog } from "../shared";
import { useDispatch } from 'react-redux';
import { showLogin } from '../../redux/reducers/ui';

export function AuthenticationDialog({ onClose }) {
    const dispatch = useDispatch();

    const handleAuthenticationClosed = () => {
        dispatch(showLogin(false));

        if (onClose) {
            onClose();
        }
    }

    return (
        <Dialog onClose={handleAuthenticationClosed}>
            <Authentication />
        </Dialog>
    );
}