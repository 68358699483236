import { LogoLink } from '../logo';
import { ClosableBurgerButton } from '../shared';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { HeaderLogin } from './login/HeaderLogin';

export function Header() {
    const [isMenuActive, setIsMenuActive] = useState(false);

    const handleMenuStateChanged = (isActive) => {
        setIsMenuActive(isActive);
    }

    const handleMenuClick = (event) => {
        if (event.target.tagName === "A") {
            setIsMenuActive(false);
        }
    }

    return (
        <header id="main-site-header" className="main-header">
            <div className="header__content container__limited-width">
                <div className="header__brand">
                    <LogoLink></LogoLink>
                </div>
                <div className="header__nav-content">
                    <div className="header__nav">
                        <Link to="/" className="header__nav-item">Home</Link>
                        <Link to="/courses" className="header__nav-item">Courses</Link>
                        <Link to="/consulting" className="header__nav-item">Consulting</Link>
                        <Link to="about-us" className="header__nav-item">About Us</Link>
                        <Link to="contact-us" className="header__nav-item">Contact Us</Link>
                        <HeaderLogin />
                    </div>
                </div>
                <div className="header__burger-button">
                    <ClosableBurgerButton onStateChanged={handleMenuStateChanged} isActive={isMenuActive} />
                </div>
                {isMenuActive && <div className="header__backdrop"></div>}
                {isMenuActive && <div className="header__burger">
                    <div className="header__nav" onClick={handleMenuClick}>
                        <Link to="/" className="header__nav-item">Home</Link>
                        <Link to="/courses" className="header__nav-item">Courses</Link>
                        <Link to="/consulting" className="header__nav-item">Consulting</Link>
                        <Link to="about-us" className="header__nav-item">About Us</Link>
                        <Link to="contact-us" className="header__nav-item">Contact Us</Link>
                        <HeaderLogin />
                    </div>
                </div>}
            </div>
        </header>
    )
}