import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        showLogin: false
    },
    reducers: {
        showLogin: (state, action) => {
            state.showLogin =action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { showLogin } = uiSlice.actions;

export default uiSlice.reducer;