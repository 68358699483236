import { useParams, useNavigate } from "react-router-dom";

export function Consulting() {
    const params = useParams()
    const navigate = useNavigate()

    const handleContactUs = () => {
        navigate("/contact-us")
    }

    return (
        <div className="consulting__container">
            <div className="content container__limited-width">
                <h1 className="heading size-1 text-color-blue title">Need real experts working with you?</h1>
                <div className="text">
                    NS Edutech is composed of React and Angular professionals with expertise gained all over the world.
                    We put this expertise to work for you.
                    Our experts are able to quickly identify, predict, and satisfy our clients' current and future need.
                    Our clients trust us to provide them with the knowledge and skill to tackle every challenge and succeed at every opportunity.
                    We serve all industries, including banking, finance, legal services, life sciences & healthcare, technology, media, and the public sector.'
                </div>
                <button className="button block contact-us" onClick={handleContactUs}>
                    Contact Us
                </button>
            </div>
        </div>
    )
}