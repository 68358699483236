export function CourseRegistration({ onCancel, onSubmit }) {

    const handleCancel = () => {
        onCancel();
    }

    const handleSubmit = () => {
        onSubmit();
    }
    return (
        <div className="course-registration">
            <div className="registration-status"></div>
            <div className="header">Please confirm your registration</div>
            <div>Course: ReactJS with Javascript</div>
            <div>Duration: 45 hours</div>
            <div>Schedule: 1st Mar 2022 to 31st Mar 2022</div>
            <div className="container__row action__buttons">
                <button type="submit" className="button small block view-details" onClick={handleCancel}>
                    Cancel
                </button>
                <button type="submit" className="button small block view-details" onClick={handleSubmit}>
                    Confirm
                </button>
            </div>
        </div>
    );
}