import { BiSupport } from "react-icons/bi";
import { MdGroups } from "react-icons/md";
import { FcCollaboration } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FaLaptopCode } from "react-icons/fa";
import { GrUserExpert } from "react-icons/gr";
import { GiNinjaHeroicStance } from "react-icons/gi";

export function WhyUs({ }) {
    return (
        <>
            <div className="why-us container__limited-width">
                <div className="header text__bold">NS Edutech Advantage</div>
                <div className="why-us__card-list">
                    <div className="card">
                        {/* <FcManager className="icon very-large" /> */}
                        <GiNinjaHeroicStance className="icon blue very-large" />
                        <div className="highlight-text">Learn from working Architects and Consultants</div>
                        <div>Our trainers are working as consultants and architects in top companies.
                            They have experience of working on multiple large scale enterprise projects.
                            They will be able to train you better to face the real world.
                        </div>

                    </div>

                    <div className="card">
                        {/* <FcCollaboration className="icon very-large" /> */}
                        <FaLaptopCode className="icon blue very-large" />
                        <div className="highlight-text">Hands on practice sessions</div>
                        <div>We understand that programming can't be learned just by watching someone else code.
                            So we have designed our courses to give you ample practice sessions.
                            Our instructor will always be there if you need any help.
                        </div>

                    </div>

                    <div className="card">
                        <MdGroups className="icon blue very-large" />
                        <div className="highlight-text">Group Projects</div>
                        <div>Each batch will be divided into groups, and each group will be assigned a project that they need to finish within given time.
                            This helps you learn team collaboration and you learn more.
                        </div>

                    </div>

                    <div className="card">
                        <BiSupport className="icon blue very-large" />
                        <div className="highlight-text">15 days support after course duration</div>
                        <div>We understand that after completing our course you would like to explore the learned skills
                            and you will come up with questions and doubts.
                            But dont worry, we will be there to handle your queries and doubts for 15 days after the course completion.
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}