export function toPascal(text) {
    if (!text) {
        return text;
    }

    return text.charAt(0).toLowerCase() + text.slice(1);
}

export function trim(text) {
    if (!text) {
        return text;
    }

    return text.trim();
}