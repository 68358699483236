import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    isUserLoggedIn: false
  },
  reducers: {
    // dispatch(userLoggedIn(user)); 
    // action.type = authentication/userLoggedIn
    // action.payload= user 
    userLoggedIn: (state, action) => {
      state.isUserLoggedIn = true;
      state.user = action.payload;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    logout: (state, action) => {
      state.isUserLoggedIn = false;
      state.user = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { userLoggedIn, logout, incrementByAmount } = authenticationSlice.actions;

export default authenticationSlice.reducer;

      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes