import { useState } from 'react';
import { InputErrorWrapper } from '../../shared';
import { backendToUiErrors, hasErrors } from "../../../validators";

export function NewPassword({ data, onPasswordChange, onSuccess, onError, onSetIsBusy, verificationMethod }) {
    const [validations, setValidations] = useState({});
    const [confirmPassword, setConfirmPassword] = useState('');
    const { password } = data;

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name == 'password') {
            onPasswordChange(value);
        }
        else if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        var validation = validateData({ password, confirmPassword });
        setValidations(validation);

        if (hasErrors(validation)) {
            return;
        }

        onSetIsBusy(true);
        try {
            var result = await verificationMethod();
            onSuccess();
        }
        catch (backendError) {
            const { response: errors } = backendError;

            if (!errors) {
                return;
            }

            let uiErrors = backendToUiErrors(errors, uiFieldMapper, errorTypeMapper);

            if (onError) {
                uiErrors = onError(uiErrors) || {};
            }

            setValidations(uiErrors);
        }

        onSetIsBusy(false);
    }


    return (
        <div className="email-verification__container">
            <div>
                Reset Password
            </div>
            <form className="form-field-spacing">
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">New Password</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.password}>
                            <input type="text" name="password" className="form-field" required="" aria-required="true" value={password} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>

                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">Confirm Password</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.confirmPassword}>
                            <input type="text" name="confirmPassword" className="form-field" required="" aria-required="true" value={confirmPassword} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>

                <div className="container__row action__buttons">
                    <button type="submit" className="button small button-form next" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

function validateData({ password, confirmPassword }) {
    var validation = {};

    if (!password) {
        validation.password = ["Password is required"]
    }

    if (!confirmPassword) {
        validation.confirmPassword = ["Confirm Password is required"]
    }

    if (password && confirmPassword && password !== confirmPassword) {
        validation.confirmPassword = ["Password and Confirm Password dont match"]
    }

    return validation;
}

function uiFieldMapper(field) {
    if (field == "Status") {
        return "code";
    }
}

function errorTypeMapper(errorType, field) {
    if (errorType == "CodeMismatch") {
        return "Verification code is invalid";
    }

    return "";
}