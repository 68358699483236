import { Link } from "react-router-dom";
import { FaTwitter, FaFacebook, FaGoogle, FaLinkedin } from 'react-icons/fa';

export function Footer() {
    return (
        <div className="footer__container">
            <div className="content container__limited-width">
                <section className="company">
                    <div className="company-name title">NS Edutech</div>
                    <div className="sub-content">NS Edutech is a renowned React/Angular training and consulting company.</div>
                </section>
                <section>
                    <div className="title"> Links </div>
                    <div className="company__links sub-content">
                        <Link to="/" className="nav-item">Home</Link>
                        <Link to="/courses" className="nav-item">Courses</Link>
                        <Link to="/consulting" className="nav-item">Consulting</Link>
                        <Link to="about-us" className="nav-item">About Us</Link>
                        <Link to="contact-us" className="nav-item">Contact Us</Link>
                    </div>
                </section>
                <section className="follow-us">
                    <div className="title">Follow Us</div>
                    <div className="sub-content">
                        <a target="_blank" href="https://twitter.com/nsedutech">
                            <FaTwitter className="icon blue medium" />
                        </a>
                        <a target="_blank" href="https://www.facebook.com/nsedutech">
                            <FaFacebook className="icon blue medium" />
                        </a>

                        <FaGoogle className="icon blue medium" />
                        <a target="_blank" href="https://www.linkedin.com/company/ns-edutech">
                            <FaLinkedin className="icon blue medium" />
                        </a>

                    </div>
                </section>
            </div >
        </div >
    )
}

