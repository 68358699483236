import { jsonHttpService } from './http';

function googleLogin(tokenId) {
    const uri = process.env.REACT_APP_API_URI + "account/authenticate-social";
    return jsonHttpService.post(uri, { provider: "google", token: tokenId });
}

function facebookLogin(tokenId) {
    const uri = process.env.REACT_APP_API_URI + "account/authenticate-social";
    return jsonHttpService.post(uri, { provider: "facebook", token: tokenId });
}

function signup(data) {
    const uri = process.env.REACT_APP_API_URI + "account/register";
    return jsonHttpService.post(uri, data);
}

function login(data) {
    const uri = process.env.REACT_APP_API_URI + "account/authenticate";
    return jsonHttpService.post(uri, data);
}

function getResetPasswordCode(emailId) {
    const uri = process.env.REACT_APP_API_URI + "account/generate-reset-password-code";
    return jsonHttpService.post(uri, { emailId });
}

function resetPassword(data) {
    const uri = process.env.REACT_APP_API_URI + "account/reset-password";
    return jsonHttpService.post(uri, data);
}

const authenticationService = {
    googleLogin,
    facebookLogin,
    signup,
    login,
    getResetPasswordCode,
    resetPassword
}

export default authenticationService;