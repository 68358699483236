import store from '../../redux/store/store';

export function authenticatedRequest({ url, data, requestParameters, extraParameters }) {
    var token = store.getState().authentication.user.jwtToken;
    requestParameters.headers = { ...requestParameters.headers, 'Authorization': `Bearer ${token}` };
    return { url, data, requestParameters, extraParameters };
}

// For redirection
/*0
      const request = window.fetch(url, options)
        .then((d) => {
            if (d.status === 401) {
                // Unauthorized
                console.log('not authorized');
                return refreshAccesToken();
            }
            else {
                return d.json();
            }
        });

         const refreshAccesToken = () => {
        window.fetch(opts.url, {
            method: 'get',
            credentials: 'include'
        }).then((d) => {
            // For this example, we can omit this, we can suppose we always receive the access token
            if (d.status === 401) {
                // Unauthorized and the cookie used to validate and refresh the access token has expired. So we want to login in to the app again
                window.location.href = opts.unauthorizedRedirect;
            }

  const response = await fetch(
  "https://jsonplaceholder.typicode.com/todos"
);

*/