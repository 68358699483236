import { responseError, responseAsJson } from './http.interceptors';

const GET = "GET";
const PUT = "PUT";
const POST = "POST";
const DELETE = "DELETE";

// Fetch promises only reject when a network error occurs.4xx and 5xxare not network errors, so nothing to catch. Use response.ok.
/*
 fetch(url).then(status)
    .then(res => res.json())
    .catch(error => {
        return Promise.reject()
    })
 */

// above change using interceptor
const responseInterceptors = [];//[responseError];

function interceptedFetch(url, requestOptions) {
    var promise = fetch(url, requestOptions);
    let newPromise = promise;

    responseInterceptors.forEach(i => {
        newPromise = newPromise.then(response => i(response));
    });

    return newPromise;
}

function get(url, requestParameters) {
    const requestOptions = createRequestOptions(GET, null, requestParameters);
    return interceptedFetch(url, requestOptions);
}

function post(url, data, requestParameters) {
    const requestOptions = createRequestOptions(POST, data, requestParameters);
    return interceptedFetch(url, requestOptions);
}

function _delete(url, requestParameters) { // delete is reserved keyword
    const requestOptions = createRequestOptions(DELETE, requestParameters);
    return interceptedFetch(url, requestOptions);
}

function update(url, data, requestParameters) {
    const requestOptions = createRequestOptions(PUT, data, requestParameters);
    return interceptedFetch(url, requestOptions);
}

function createRequestOptions(method, data, requestParameters) {
    const defaultOptions = getDefaultRequestOptions(method);

    // override the passed parameters
    const requestOptions = requestParameters ? {
        ...defaultOptions,
        ...requestParameters, // Any field set by default will be overridden
    } : defaultOptions;

    if (method === POST || method === PUT) {
        requestOptions.body = data;
    }

    return requestOptions;
}

function getDefaultRequestOptions(method) {
    return { method };
}

const httpService = {
    get,
    update,
    post,
    delete: _delete
}

export default httpService;