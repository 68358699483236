import { useState } from "react";
import { LoadingButton } from "../shared";
import { InputErrorWrapper } from "../shared";
import { validateEmail, hasErrors } from '../../validators';
import { enquiryService } from "../../services";

const emptyMessage = { fullName: '', eMailId: '', contactNumber: '', message: '' };

export function ContactUs() {

    const [message, setMessage] = useState(emptyMessage);
    const [submitResponse, setSubmitResponse] = useState({ show: false, message: null, validation: {} });
    const [showLoading, setShowLoading] = useState(false);
    const [validations, setValidation] = useState({});

    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        setSubmitResponse({ show: false, message: null });
        let newValidations = {};

        if (!message.message || message.message == "") {
            newValidations.message = ["Message is required"]
        }
        if (!message.fullName || message.fullName == "") {
            newValidations.fullName = ["Full Name is required"]
        }
        if (!message.eMailId || message.eMailId == "") {
            newValidations.eMailId = ["Email id is required"]
        }
        else if (!validateEmail(message.eMailId)) {
            newValidations.eMailId = ["Email id is not in correct format"]
        }

        setValidation(newValidations);

        if (hasErrors(newValidations)) {
            return;
        }

        setShowLoading(true);

        try {
            await enquiryService.sendMessage(message);
            setMessage(emptyMessage);
            setSubmitResponse({ show: true, message: "Thanks for contacting NS Edutech, we will get back to you soon." });
        }
        catch (ex) {
            console.log(ex);
            setSubmitResponse({ show: true, message: "Some error occured while submitting your message. Please try again" });
        }

        setShowLoading(false);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMessage({ ...message, [name]: value });
    }

    return (
        <div id="contact" className="contact-us__container">
            <div className="contact-us__content container__limited-width">
                <div className="primary__content">
                    <h1 className="heading size-1 text-color-blue">Hello</h1>
                    <div className="text">Ask questions about our online courses, consulting services, or just ask us a general question. We will be happy to help you.</div>
                </div>
                <section className="contact-us heading size-2">
                    <h1>Contact Us</h1>
                    <form className="spacing-small">
                        {submitResponse.show && <div className="notice spacing notice-type-success">{submitResponse.message}</div>}
                        <div className="form-field-wrap required">
                            <label htmlFor="form-field-name" className="form-field-label">Full Name (required)</label>
                            <div className="form-field-input">
                                <InputErrorWrapper errors={validations.fullName}>
                                    <input type="text" name="fullName" className="form-field" required="" aria-required="true" value={message.fullName} onChange={handleInputChange} />
                                </InputErrorWrapper>
                            </div>
                        </div>
                        <div className="form-field-wrap required">
                            <label htmlFor="form-field-email" className="form-field-label">Email (required)</label>
                            <div className="form-field-input">
                                <InputErrorWrapper errors={validations.eMailId}>
                                    <input type="email" id="form-field-email" name="eMailId" className="form-field" required="" aria-required="true" value={message.eMailId} onChange={handleInputChange} />
                                </InputErrorWrapper>
                            </div>
                        </div>
                        <div className="form-field-wrap required">
                            <label htmlFor="form-field-contact-number" className="form-field-label">Contact Number (optional)</label>
                            <div className="form-field-input">
                                <InputErrorWrapper errors={validations.contactNumber} >
                                    <input type="text" id="form-field-contact-number" name="contactNumber" className="form-field" required="" aria-required="true" value={message.contactNumber} onChange={handleInputChange} />
                                </InputErrorWrapper>
                            </div>
                        </div>
                        <div className="form-field-wrap">
                            <label htmlFor="form-field-message" className="form-field-label">Message (required)</label>
                            <div className="form-field-input">
                                <InputErrorWrapper errors={validations.message}>
                                    <textarea name="message" className="form-field" value={message.message} onChange={handleInputChange}>
                                    </textarea>
                                </InputErrorWrapper>
                            </div>
                        </div>

                        <footer className="spacing">
                            <LoadingButton className="button block" showLoading={showLoading} onClick={handleSubmitMessage} isDisabled={showLoading}>
                                Send Message
                            </LoadingButton>

                            <div className="text-small">Or email us at
                                {/* <a href="/cdn-cgi/l/email-protection#5a323f3636351a283f3b392e2e283b333433343d74393537"> */}
                                {/* <span className="__cf_email__" data-cfemail="89e1ece5e5e6c9fbece8eafdfdfbe8e0e7e0e7eea7eae6e4">[email&#160;protected]</span> */}
                                <span className="__cf_email__" >{"  " + "info.nsedutech@gmail.com"}</span>
                                {/* </a> */}
                            </div>
                        </footer>
                    </form>

                </section>
            </div>
        </div >
    )
}

function ErrorWrapper({ }) {

}

