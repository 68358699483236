import { jsonHttpService } from './http';

async function  siteAccess() {
    const uri = process.env.REACT_APP_API_URI + "clientinfo/siteaccess";
    try {
        await jsonHttpService.post(uri, {});
    }
    catch { }
}

const accessInfoService = {
    siteAccess
}

export default accessInfoService;