import { CircularSpinner } from "../spinner/circular-spinner/CircularSpinner";

export function LoadingButton({ className, onClick, children, showLoading, isDisabled }) {
    return (
        <button type="submit" className={className} onClick={onClick} disabled={isDisabled}>
            <div className="loading-button__content">
                {showLoading && <div className="spinner"><CircularSpinner /></div>}
                {children}
            </div>
        </button>
    )
}