export function InputErrorWrapper({ children, errors }) {
    const hasErrors = errors != null && errors.length !== 0;
    const className = hasErrors ? "error-wrapper error" : "error-wrapper";

    return (
        <div className={className}>
            {children}
            {(hasErrors && errors.length == 1) && <div className="form-field-error">{errors[0]}</div>}
        </div>
    );
}