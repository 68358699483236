import { toPascal } from "../helpers";

export function hasErrors(errorObj) {
    return Object.keys(errorObj).length != 0;
}

export function backendToUiErrors(errorObject, uiFieldMapper, errorTypeMapper) {
    const uiErrors = {};

    if (!errorObject.hasErrors) {
        return uiErrors;
    }

    const errors = errorObject.errors;

    if (!uiFieldMapper) {
        uiFieldMapper = toPascal;
    }

    if (!errorTypeMapper) {
        errorTypeMapper = getErrorFromType;
    }

    for (let error of errors) {
        let uiField = uiFieldMapper(error.field) || toPascal(error.field);

        if (!uiErrors[uiField]) {
            uiErrors[uiField] = [];
        }

        let errorMessage = error.errorType ? errorTypeMapper(error.errorType, error.field) || getErrorFromType(error.errorType, error.field) : error.error;
        uiErrors[uiField].push(errorMessage);
    }

    return uiErrors;
}

export function getErrorFromType(errorType, field) {
    if (errorType == "missing") {
        return `${field} is required`;
    }
}