import { WhyUs } from "../why-us";

export function AboutUs() {
    return (
        <div className="about-us__container">
            <div className="content container__limited-width">
                <h1 className="heading size-1 text-color-blue about-us__title">About Us</h1>
                <div className="text">
                    NS Edutech is considered best training institute for learning React, Angular, and .Net.
                    <div>
                        At NS Edutech, we believe that all it takes to be a good programmer is determination, practice and right guidance.
                        You already have determination to learn and thats why you are here.
                    </div>
                    <div>
                        NS Edutech will help with providing right guidance and other aspects required to succeed.
                    </div>

                </div>
                <div className="why-we-do text__bold">Why we do it</div>
                <div>
                    We are passionate about technology, and very much interested to transforming the way technology has been taught. We want people to learn and understand the real essence of tech.
                </div>
                <div>
                <div className="why-different text__bold"> Why We're Different</div>
                   
                    We are experts in teaching React, Angular and .Net technologies.
                    At NS Edutech, Quality is the highest priority and we make no compromises with it. 
                    We are concerned about each and every student joining NS Edutech. 
                    We have the best post training support.
                    At NS Edutech you not get a chance to work on live projects.We make learning fun and very much realistic. We always support and push our students, professionals to be innovative, think out of the box and get the next big thing.

                    We are passionate about delivering the technology in a right way to the professionals, students and corporate to help them achieve excellence.
                </div>
                <WhyUs/>
            </div>
        </div>
    );
}

/*
At BitCode, we believe there is a better way to teach technology! We are the trend setters in IT training and known for the result oriented approach. We are completely focused on the quality of training and building innovative approach in our students.
*/

// We are the trend setters in IT training and known for the result oriented approach.
// We are completely focused on the quality of training and building innovative approach in our students.
// We want people to learn and understand the real essence of tech.

// we are constantly exploring new fields of knowledge as well as new training methodologies.

// “ We are learners! At BitCode we are continually learning and innovating new ways of delivering tech efficiently to our students ”
