import { SignUp } from "./signup";
import { Login } from "./login";
import { ForgotPassword } from "./forgot-password";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userLoggedIn, showLogin } from '../../redux/reducers';
import { BusyOverlay } from "../shared/busy-overlay";

export function Authentication() {
    var dispatch = useDispatch();
    const [primaryStage, setPrimaryStage] = useState("login");
    const [isBusy, setIsBusy] = useState(false);

    const handleSignupClicked = () => {
        setPrimaryStage("signup");
    }

    const handleLoginClicked = () => {
        setPrimaryStage("login");
    }

    const handleForgotPasswordClicked = () => {
        setPrimaryStage("forgot-password");
    }

    const handleAuthenticationSuccess = (user) => {
        //console.log(user);
        dispatch(userLoggedIn(user));
    }

    const handleClose = () => {
        dispatch(showLogin(false));
    }

    const handleSetIsBusy = (busy) => {
        setIsBusy(busy);
    }

    return (
        <>
            <div className="authentication__container">
                {isBusy && <BusyOverlay spinnerClassName='spinner' />}
                {primaryStage == "login"
                    && <Login
                        onSignupClicked={handleSignupClicked}
                        onForgotPasswordClicked={handleForgotPasswordClicked}
                        onAuthenticationSuccess={handleAuthenticationSuccess}
                        onSetIsBusy={handleSetIsBusy}
                        onClose={handleClose}
                    />}
                {primaryStage == "signup"
                    && <SignUp 
                        onLoginClicked={handleLoginClicked}
                        onAuthenticationSuccess={handleAuthenticationSuccess}
                        onSetIsBusy={handleSetIsBusy}
                        onClose={handleClose}
                    />}
                {primaryStage == "forgot-password"
                    && <ForgotPassword
                        onLoginClicked={handleLoginClicked}
                        onAuthenticationSuccess={handleAuthenticationSuccess}
                        onSetIsBusy={handleSetIsBusy}
                        onClose={handleClose}
                    />}
            </div>
        </>
    );
}