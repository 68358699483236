import { useParams, useNavigate } from "react-router-dom";
export function CourseCard({ svg, courseName, isActive }) {

    const params = useParams();
    const navigate = useNavigate();
    const handleViewDetails = () => {
        navigate(`/courses/${courseName}`);
    }

    return (
        <div className="card course__card">
            <div className="course-logo">{svg}</div>
            <div className="course-name">{courseName}</div>
            {isActive && <div className="course-detail__table">
                <div>Mode: <span>Online</span></div>
                <div>Duration: <span>45 hours</span></div>
                <div>Enrolment: <span>Started</span></div>
            </div>}

            <div className="footer">
                <button type="submit" className="button small block view-details" onClick={handleViewDetails}>
                    View Details
                </button>
            </div>
            {!isActive && <div className="overlay">
                <div className="coming-soon">Coming Soon</div>
            </div>}
        </div>
    );
}