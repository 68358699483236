import { Header } from '../header';
import { Footer } from '../footer';
import { Home } from '../home';
import { ContactUs } from '../contact-us';
import { AboutUs } from '../about-us';
import { Courses } from '../courses';
import { Consulting } from '../consulting';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect } from 'react';
import { ReactCourseDetail } from '../course-detail';
import { accessInfoService } from '../../services';
import { ComponentHost } from '../component-host';
import { User } from '../user';
import { useDispatch, useSelector } from 'react-redux';

export function AppPage() {

    useEffect(() => {
        accessInfoService.siteAccess();
    }, [])

    return <div className="app-container">
        <ComponentHost></ComponentHost>
        <Router>
            <Header />
            <RoutesComponent />
            <Footer />
        </Router>
    </div >
}

function RoutesComponent() {

    // useLocation must be used in context of Router
    const { pathname } = useLocation();
    const isUserLoggedIn = useSelector((state) => state.authentication.isUserLoggedIn);

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    return (

        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="courses" element={<Courses />} />
            <Route exact path="consulting" element={<Consulting />} />
            <Route exact path="about-us" element={<AboutUs />} />
            <Route exact path="contact-us" element={<ContactUs />} />
            <Route exact path="courses/react" element={<ReactCourseDetail />} />
            {isUserLoggedIn && <Route exact path="user" element={<User />} />}
        </Routes>
    );
}