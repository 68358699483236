import { SocialLogin } from "../social-login";
import { useState, useRef } from "react";
import authenticationService from "../../../services/authentication.service";
import { InputErrorWrapper } from '../../shared';
import { validateEmail, backendToUiErrors, hasErrors } from "../../../validators";
import { CodeVerification } from '../code-verification';
import { AuthenticationStepsSuccess } from "../authentication-steps-success";
import { trim } from "../../../helpers";

export function Login({ onSignupClicked, onForgotPasswordClicked, onAuthenticationSuccess, onSetIsBusy, onClose }) {
    const [stage, setStage] = useState("login");
    const [signUpCode, setSignUpCode] = useState('');
    var loginDataRef = useRef();

    const handleEmailConfirmationRequired = () => {
        setStage("email-confirmation");
    }

    const handleLogin = (loginData) => {
        loginDataRef.current = loginData;
    }

    const handleEmailConfirmationCompleted = (user) => {
        setStage("success");
        onAuthenticationSuccess(user);
    }

    const login = () => {
        return authenticationService.login({
            emailConfirmationCode: signUpCode,
            emailId: loginDataRef.current.emailId,
            password: loginDataRef.current.password
        });
    }

    const handleCodeChange = (code) => {
        setSignUpCode(code);
    }

    return (
        <div className="login__container">
            {stage == "login" &&
                <LoginForm
                    onLogin={handleLogin}
                    onAuthenticationSuccess={onAuthenticationSuccess}
                    onSignupClicked={onSignupClicked}
                    onForgotPasswordClicked={onForgotPasswordClicked}
                    onSetIsBusy={onSetIsBusy}
                    onClose={onClose}
                    onEmailConfirmationRequired={handleEmailConfirmationRequired}
                />
            }
            {stage == "email-confirmation" &&
                <CodeVerification
                    data={{ code: signUpCode, emailId: loginDataRef.current.emailId }}
                    onSuccess={handleEmailConfirmationCompleted}
                    onSetIsBusy={onSetIsBusy}
                    onCodeChange={handleCodeChange}
                    verificationMethod={login}
                />
            }
            {stage == "success"
                && <AuthenticationStepsSuccess message={'Your email id is verified!!'} />}
        </div>


        // <section className="register-account">
        //     <div className="signform">
        //         <div className="left">
        //             <a href="#" className="bts-a" onClick={onSignupClicked}>
        //                 Don't have an account? Sign up!
        //             </a>
        //             <SocialLogin onAuthenticationSuccess={onAuthenticationSuccess} />
        //         </div>
        //         <div className="right">
        //             <div className="headit">
        //                 <h4>Login To Your Account</h4>
        //             </div>
        //             <div className="form">
        //                 <form className="login-form" id="login-form">
        //                     <InputErrorWrapper errors={validations.emailId} >
        //                         <input type="text" placeholder="Email Id" name="emailId" value={loginData.emailId} onChange={handleInputChange} />
        //                     </InputErrorWrapper>
        //                     <InputErrorWrapper errors={validations.password} >
        //                         <input type="password" placeholder="Password" name="password" value={loginData.password} onChange={handleInputChange} />
        //                     </InputErrorWrapper>
        //                     <input className="subbt" type="submit" value="Log In" onClick={handleLogin} />
        //                 </form>
        //                 <input type="checkbox" id="remember" name="remember"></input>
        //                 <span> Remember Me</span><a href="#">Forgot your password?</a>
        //             </div>
        //         </div>
        //     </div>
        // </section>

    );
}

function LoginForm({ onSignupClicked, onForgotPasswordClicked, onLogin, onAuthenticationSuccess, onEmailConfirmationRequired, onSetIsBusy, onClose }) {
    const [loginData, setLoginData] = useState({ emailId: '', password: '' });
    const [validations, setValidations] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();

        var validation = validateLoginData(loginData);
        setValidations(validation);

        if (hasErrors(validation)) {
            return;
        }

        onSetIsBusy(true);

        try {
            onLogin(loginData);
            var response = await authenticationService.login(loginData);
            onAuthenticationSuccess(response);
            onClose();
        }
        catch (backendError) {
            const { response: errorObject } = backendError;

            if (!errorObject) {
                return;
            }

            if (errorObject.errors.find(e => e.errorType == "EmailIdNotVerified")) {
                onSetIsBusy(false);
                onEmailConfirmationRequired();
                return;
            }

            const uiErrors = backendToUiErrors(errorObject, uiFieldMapper, errorTypeMapper);
            setValidations(uiErrors);
        }

        onSetIsBusy(false);
    }

    const handleInputChange = (event) => {
        setError('')
        let { name, value } = event.target;

        if (name === "emailId") {
            value = trim(value);
        }

        setLoginData({ ...loginData, [name]: value });
    }

    const handleShowPasswordChange = (event) => {
        setShowPassword(event.target.checked);
    }

    const onSocialLoginAuthenticationSuccess = (user) => {
        onAuthenticationSuccess(user);
        onClose();
    }

    const handleSocialAuthenticationError = (socialLoginError) => {

        setError(socialLoginError);
    }

    const passwordInputType = showPassword ? "text" : "password";

    return (
        <section className="login-form__container">
            {error &&
                <div className="error">{error}</div>
            }
            <form className="form-field-spacing">
                {/* {submitResponse.show && <div className="notice spacing notice-type-success">{submitResponse.message}</div>} */}
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">Email ID</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.emailId} >
                            <input className="form-field" type="text" placeholder="Email Id" name="emailId" value={loginData.emailId} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">Password</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.password} >
                            <input className="form-field" type={passwordInputType} placeholder="Password" name="password" value={loginData.password} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>

                <div className="container__row">
                    <input type="checkbox" name="showPassword" checked={showPassword} onChange={handleShowPasswordChange} />
                    <span className="checkbox__label">Show Password</span>
                    <a className="forgot-password" href="#" onClick={onForgotPasswordClicked}>
                        Forgot Password
                    </a>
                </div>
                <div className="action">
                    <a href="#" onClick={onSignupClicked}>
                        Create account
                    </a>
                    <button type="submit" className="button small button-form " onClick={handleLogin}>
                        Login
                    </button>
                </div>
            </form>
            <div className="divider__horizontal social-login__separator"></div>
            <div >or, login with</div>
            <SocialLogin onAuthenticationSuccess={onSocialLoginAuthenticationSuccess} onSetIsBusy={onSetIsBusy} onError={handleSocialAuthenticationError} />
        </section>
    );
}

function validateLoginData(loginData) {
    var validation = {};

    if (!validateEmail(loginData.emailId)) {
        validation.emailId = ["Email id is not in correct format"]
    }

    if (!loginData.password) {
        validation.password = ["Password is required"]
    }

    return validation;
}

function uiFieldMapper(field) {
    if (field == null) {
        return "password";
    }

    return null;
}

function errorTypeMapper(errorType, field) {
    if (errorType == "UserIdOrPasswordIncorrect") {
        return "EmailId or password is incorrect";
    }
    if (errorType == "UserIdNotAvailable") {
        return "EmailId or password is incorrect";
    }
}

// <a href="#" style="float:right;margin-right:35px;font-size: 0.9em;" className="bts-a">
// Don't have an account? Sign up!
// </a>

//<span style="color:#b6b6b6;font-size: 0.9em;"> Remember Me</span><a href="#">Forgot your password?</a>