import { useState } from 'react';
import { InputErrorWrapper } from '../../shared';
import { backendToUiErrors, hasErrors } from "../../../validators";

export function CodeVerification({ data: { emailId, code }, onCodeChange, onSuccess, onError, onSetIsBusy, verificationMethod }) {
    const [validations, setValidations] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onCodeChange(value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        var validation = validateData({ code });
        setValidations(validation);

        if (hasErrors(validation)) {
            return;
        }

        onSetIsBusy(true);
        try {
            var result = await verificationMethod();
            onSuccess();
        }
        catch (backendError) {
            const { response: errors } = backendError;

            if (!errors) {
                return;
            }

            let uiErrors = backendToUiErrors(errors, uiFieldMapper, errorTypeMapper);

            if (onError) {
                uiErrors = onError(uiErrors) || {};
            }

            setValidations(uiErrors);
        }

        onSetIsBusy(false);
    }

    return (
        <div className="email-verification__container">
            <div>
                An email has been sent to {emailId} with the verification code.
            </div>
            <form className="form-field-spacing">
                <div className="form-field-wrap required">
                    <label htmlFor="form-field-name" className="form-field-label">Email Verification Code</label>
                    <div className="form-field-input">
                        <InputErrorWrapper errors={validations.code}>
                            <input type="text" name="code" className="form-field" required="" aria-required="true" value={code} onChange={handleInputChange} />
                        </InputErrorWrapper>
                    </div>
                </div>

                <div className="container__row action__buttons">
                    <button type="submit" className="button small button-form next" onClick={handleSubmit}>
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
}

function validateData({ code }) {
    var validation = {};

    if (!code) {
        validation.code = ["Verification code is required"]
    }

    return validation;
}

function uiFieldMapper(field) {
    if (field == "Status") {
        return "code";
    }
}

function errorTypeMapper(errorType, field) {
    if (errorType == "CodeMismatch") {
        return "Verification code is invalid";
    }

    return "";
}