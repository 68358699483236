import { useEffect, useRef } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import authenticationService from '../../../services/authentication.service';
import { FaTwitter, FaFacebook, FaGoogle, FaLinkedin } from 'react-icons/fa';
import FacebookLoginComponent from 'react-facebook-login/dist/facebook-login-render-props'

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;// Reading google client id from .env file
const facebookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

export function SocialLogin({ onAuthenticationSuccess, onSetIsBusy, onError }) {

    const onAuthenticationResponse = (response) => {
        onAuthenticationSuccess(response);
    };

    return (
        <div className="social-login__container">


            <FacebookLogin onSuccess={onAuthenticationResponse} onSetIsBusy={onSetIsBusy} onError={onError} />
            <GoogleLogin onSuccess={onAuthenticationResponse} onSetIsBusy={onSetIsBusy} onError={onError} />
        </div>
    );
}

function GoogleLogin({ onSuccess, onSetIsBusy, onError }) {

    const loginClickedRef = useRef(false);
    const initializationErrorRef = useRef();

    // if user is already signed in(maybe from previous session), this method automatically called, without signing in again.
    const handleSuccess = async (res) => {
        onSetIsBusy(false);
        if (!loginClickedRef.current) {
            return;
        }

        try {
            onSetIsBusy(true);
            var result = await authenticationService.googleLogin(res.tokenId);
            onSuccess(result);
        }
        catch (ex) {
            console.log(ex);
        }
        onSetIsBusy(false);
    };

    const onFailure = (response) => {
        if (!loginClickedRef.current) {
            initializationErrorRef.current = response;
            return;
        }

        reportError(response);
        onSetIsBusy(false);
    };

    const reportError = (response) => {
        onError('');

        if (response.error == "idpiframe_initialization_failed") {
            onError("Google Autnentication Error: " + response.details);
        }
        else if (response.error == "popup_closed_by_user") {
            onError("Google Autnentication Error: " + "Popup closed by user");
        }
        else {
            onError(response.toString());
        }
    }

    const { signIn } = useGoogleLogin({
        onSuccess: handleSuccess,
        onFailure,
        clientId: googleClientId,
        isSignedIn: true,
        accessType: 'offline',
    });

    const handleGoogleLogin = () => {

        // Check if error was noticed at time of initialization
        if (initializationErrorRef.current) {
            reportError(initializationErrorRef.current);
            return;
        }

        onSetIsBusy(true);
        loginClickedRef.current = true;
        signIn();
    }

    return (
        <a className="gplogin social container__row" onClick={handleGoogleLogin}>
            <FaGoogle className='social__icon' />
            <span>Sign in with Google</span>
        </a>
    );
}

function FacebookLogin({ onSuccess, onSetIsBusy, onError }) {

    const loginClickedRef = useRef(false);
    const initializationErrorRef = useRef();

    const responseFacebook = async (response) => {
        console.log(window.FB);

        // if (!loginClickedRef.current) {
        //     // window.FB.logout();
        //     return;
        // }

        onSetIsBusy(false);

        if (response.status == "unknown") {
            onError("Faceboook Authentication: Some error occured");
            return;
        }

        // if (!loginClickedRef.current) {
        //     return;
        // }

        try {
            onSetIsBusy(true);
            var result = await authenticationService.facebookLogin(response.accessToken);
            onSuccess(result);
        }
        catch (ex) {
            console.log(ex);
        }
        onSetIsBusy(false);
    }

    useEffect(() => {
        // window.FB?.logout(); 
    }, [window.FB])

    return (
        <FacebookLoginComponent
            appId={facebookClientId}
            autoLoad={false}
            isMobile={false}
            callback={responseFacebook}
            render={renderProps => (
                <a className="fblogin social container__row" onClick={renderProps.onClick}>
                    <FaFacebook className='social__icon' />
                    <span>Sign in with Facebook</span>
                </a>
            )}
        />
    );
}