import { BurgerButton } from "..";
import { useState } from "react";

export function ClosableBurgerButton({onStateChanged, isActive}) {

    const handleButtonClick = () => {
        onStateChanged(!isActive);
    }

    const className = isActive ? "burger--active" : null;

    return (
        <BurgerButton onClick={handleButtonClick} className={className}/>
    );
}