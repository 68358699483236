import { useSelector } from 'react-redux';
import { AuthenticationDialog } from '../authentication/AuthnticationDialog';

export function ComponentHost() {
    const showLogin = useSelector((state) => state.ui.showLogin);

    return (
        <div className="component__host">
            {showLogin && <AuthenticationDialog/>}
            {/* <div className="login"></div> */}
        </div>
    );
}