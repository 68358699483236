import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../reducers/authentication';
import uiReducer from '../reducers/ui';

const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        ui: uiReducer
    }
});

export default store;