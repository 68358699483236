export const responseError = (response) => { // returns object or its block of code - block of code
    if (!response.ok) {
        // throws error which should be caught by catch
        // when you throw error, the response body is ignored(for some security reason).so response.json() resolves to empty {}
        throw { statusText: response.statusText, status: response.status, response };
        // throw new Error(response.statusText);// inbuilt Error 
    }

    return response;

    // if (response.status >= 200 && response.status < 300) {
    //     return Promise.resolve(response)
    // } else {
    //     return Promise.reject(new Error({ statusText: response.statusText, status: response.status }))
    // }
}

// export function responseAsJson(response) {
//     return response.json();// returns promise
// }

export async function responseAsJson(response) {

    if (response.ok) {
        return response.json();// returns promise
    }

    if (!response.ok) {
        // throws error which should be caught by catch
        // response.json().then(jsonResponse => {
        //     throw {
        //         // statusText: response.statusText,
        //         // status: response.status,
        //         response: jsonResponse
        //     }

        const { status, statusText } = response;

        if (status === 404) {
            throw {
                statusText,
                status
            };
        }

        let jsonResponse = null;

        try {
            // if there is no response from server, example wrong url, response.json will reject.
            jsonResponse = await response.json();
        }
        catch (ex) {
            throw {
                statusText,
                status
            };
        }

        throw {
            statusText,
            status,
            response: jsonResponse
        };
    }
}

export function responseAsText(response) {
    return response.text();
}

// Requests
export function defaultRequestParameters({ url, data, requestParameters, extraParameters }) {
    requestParameters = requestParameters || {};
    return { url, data, requestParameters, extraParameters };
}

export function jsonRequest({ url, data, requestParameters, extraParameters }) {
    requestParameters.headers = { ...requestParameters.headers, 'Content-Type': 'application/json' };
    data = JSON.stringify(data);
    return { url, data, requestParameters, extraParameters };
}

export function objectToQueryString({ url, data, requestParameters, extraParameters }) {
    if (data && extraParameters.method === "GET") {
        const queryParameter = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        url += '?' + queryParameter;
    }

    return { url, data, requestParameters, extraParameters };
}