import { authenticatedJsonHttpService } from './http';

function register(courseBatchId) {
    const uri = process.env.REACT_APP_API_URI + "courseregistration/register";
    return authenticatedJsonHttpService.post(uri, { courseBatchId });
}

const courseRegistrationService = {
    register
}

export default courseRegistrationService;