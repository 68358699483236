import { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { showLogin, logout } from '../../../redux/reducers';
import { Link } from "react-router-dom";

export const HeaderLogin = () => {
    const dispatch = useDispatch();
    const { isUserLoggedIn, user } = useSelector((state) => state.authentication);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleLogin = () => {
        dispatch(showLogin(true));
    }

    const handleSignout = () => {
        dispatch(logout());
    }

    const handleDropdownClicked = () => {
        setShowDropdown(false);
    }

    const handleUserDetailShow = () => {
        setShowDropdown(true);
    }

    const handleUserDetailHide = () => {
        setShowDropdown(false);
    }

    const handleUserDetailClicked=()=>{
        setShowDropdown(true);
    }

    const getUI = () => {
        if (isUserLoggedIn) {
            return getLoggedInUI();
        }
        else {
            return getNotLoggedInUI();
        }
    }

    const getNotLoggedInUI = () => {
        return (
            <button type="submit" className="button small" onClick={handleLogin}>
                Login
            </button>
        );
    }



    const getLoggedInUI = () => {
        return (
            // These two events serve as hover
            // Hover should be on container else dropdown will hide on mouse leave
            <div className="logged-in-user__container" onMouseEnter={handleUserDetailShow} onMouseLeave={handleUserDetailHide}>
                <div className="user-detail" onClick={handleUserDetailClicked}>
                    <FaUserCircle className="user__icon" />
                    <div>{user?.fullName}</div>
                </div>
                {showDropdown && <UserDropdown onDropdownClicked={handleDropdownClicked} onSignout={handleSignout} />}
            </div>
        );
    }

    return (
        <div className="header-login">
            {getUI()}
        </div>
    );
}

function UserDropdown({ onDropdownClicked, onSignout }) {
    return (
        <>
            <div className="dropdown card" onClick={onDropdownClicked}>
                <Link to="user" className="nav-item"><div>  Dashboard</div></Link>
                {/* <div>  Profile</div> */}
                <div onClick={onSignout}>  Signout</div>
            </div>
        </>
    );
}